import dynamic from 'next/dynamic'

import { Checkbox, Image, Style, TextInput } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

import { FolderStructure } from '@/utils/makeswift/constants'

export const props = {
	className: Style({ properties: [Style.Width, Style.Border] }),
	borderRadius: Style({ properties: [Style.BorderRadius] }),
	srcMp4: TextInput({ label: 'MP4 URL' }),
	srcWebm: TextInput({ label: 'WebM URL' }),
	thumbnail: Image({ format: Image.Format.URL }),
	autoplay: Checkbox({ label: 'Auto play', defaultValue: true }),
	loop: Checkbox({ label: 'Loop', defaultValue: false }),
	muted: Checkbox({ label: 'Muted', defaultValue: true }),
	controls: Checkbox({ label: 'Controls', defaultValue: true })
}

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() => patch(import('./CustomVideo').then(({ CustomVideo }) => CustomVideo)))
	),
	{ type: 'CustomVideo', label: `${FolderStructure.UnderDevelopment}/Custom Video`, props }
)
